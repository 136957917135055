// Forms

$input-border-color: #d2d2d2 !default; // #ccc !default;

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-bottom: 4px !important;
}

.mat-mdc-form-field-infix {
    min-height: initial !important;
}

.mdc-text-field__input {
    height: inherit !important;
}

.mdc-text-field--filled {
    --mdc-filled-text-field-active-indicator-height: inherit;
    --mdc-filled-text-field-focus-active-indicator-height: inherit;
    --mdc-filled-text-field-container-shape: inherit;
}

.mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-120%) scale(0.75) !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: none !important;
}

.mat-mdc-form-field-focus-overlay {
    background: none !important;
}

.mat-mdc-tab-body {
    margin-top: 8px;
    border-radius: 8px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 30px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label, .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label--float-above {
    color: inherit;
}

.mat-mdc-text-field-wrapper .mat-mdc-icon-button.mat-mdc-button-base .mdc-icon-button {
    top: 10px !important;
    right: -10px !important;
}

.mat-mdc-form-field-icon-suffix .mat-mdc-icon-button.mat-mdc-button-base, .mat-form-field-prefix .mat-mdc-icon-button.mat-mdc-button-base{
    height: 18px !important;
}

.mat-form-field-prefix .mat-icon-button .mat-icon, .mat-form-field-suffix .mat-icon-button .mat-icon {
    font-size: 16px !important;
}

.mat-mdc-text-field-wrapper .mat-icon {
    font-size: 16px !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-icon-button .mat-mdc-button-ripple, .mat-mdc-text-field-wrapper .mat-mdc-icon-button .mat-mdc-button-persistent-ripple, .mat-mdc-text-field-wrapper .mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
    top: 4px !important;
    bottom: -4px !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-icon-button .mat-mdc-focus-indicator {
    top: 4px !important;
    bottom: -4px !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-icon-button .mat-mdc-button-ripple, .mat-mdc-text-field-wrapper .mat-mdc-icon-button .mat-mdc-button-persistent-ripple, .mat-mdc-text-field-wrapper .mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
    top: 4px !important;
    bottom: -4px !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 20px !important;
}

.mdc-text-field--filled.mdc-text-field--disabled {
    background: none !important;
}

app-stripe-payment-method .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 8px !important;
  font-size: 14px !important;
}

.view-place {
    margin-bottom: 30px !important;
}

.disabled-text {
    color: grey;
}
