@mixin update-mat-icon-button($icon-button-size, $icon-button-svg-size) {
  .mat-mdc-icon-button.mat-mdc-button-base {
    width: $icon-button-size !important;
    height: $icon-button-size !important;
    padding: 0 !important;

    & > *[role=img] {
      width: $icon-button-svg-size;
      height: $icon-button-svg-size;
      font-size: $icon-button-svg-size;

      svg {
        width: $icon-button-svg-size;
        height: $icon-button-svg-size;
      }
    }

    .mat-mdc-button-touch-target {
      width: $icon-button-size !important;
      height: $icon-button-size !important;
    }
  }
}
