.alert {
  border-radius: 3px;
  position: relative;
  padding: 20px 15px;
  line-height: 20px;

  b {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    font-size: $font-size-small;
  }

  span {
    display: block;
    max-width: 89%;
  }

  &.alert-with-icon {
    margin-top: 43px;
    padding-left: 66px;

    i[data-notify="icon"] {
      display: block;
      left: 15px;
      position: absolute;
      font-size: 20px;
      padding: 9px;
      max-width: 38px;
    }
  }

  .close {
    line-height: .5;
    background: transparent;
    border: 0;
    i {
      @extend .alert-link;
      font-size: 17px;
    }
  }

  .alert-icon {
    display: block;
    float: left;
    margin-right: $margin-base;

    i {
      margin-top: -7px;
      top: 5px;
      position: relative;
    }
  }
}