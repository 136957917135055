@use '@angular/material' as mat;

:root {
  @include mat.tabs-overrides((
          active-ripple-color: #009353,
          active-indicator-color: #009353,
  ));

  --mdc-secondary-navigation-tab-container-height: 40px;
}

.mdc-tab__text-label {
  font-size: 14px;
  text-transform: none;
}

.mdc-tab-indicator__content--underline {
  border-color: #009353 !important;
}

.mat-mdc-tab.mdc-tab {
  height: 40px;
}

.mdc-tab {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.mdc-tab__ripple::before {
  bottom: 4px !important;
  top: 4px !important;
  left: 4px !important;
  right: 4px !important;
  border-radius: 8px;
}
