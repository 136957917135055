
@mixin chip($color) {
  --mdc-chip-container-height: 26px !important;
  color: $color !important;
  opacity: 1 !important;
  border: 1.5px solid $color !important;
  background-color: transparent !important;
  font-size: 0.9rem;
  font-weight: 400;
  // width: 92%;
  height: 25px;
  justify-content: center;
}
.mat-mdc-standard-chip {
  min-width: 85px !important;;
  margin: 0 !important;
}

.chip-width-13em {
  max-width: 13em;
}

.chip-width-10em {
  max-width: 10em;
}

.chip-width-8em {
  max-width: 8em;
}

.chip-width-5em {
  max-width: 5em;
}

.mat-mdc-standard-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__text-label {
  color: inherit !important;
  font-weight: inherit;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-tracking: initial;
  text-transform: uppercase;
}

.mat-chip-disabled {
  opacity: 0;
}

.mat-chip-list-wrapper {
  justify-content: center;
  margin: -5px !important;
}

.chip-default {
  @include chip($brand-default);
}

.chip-primary {
  @include chip($brand-primary);
}

.chip-info {
  @include chip($brand-info);
}

.chip-success {
  @include chip($brand-success);
}

.chip-warning {
  @include chip($brand-warning);
}

.chip-danger {
  @include chip($brand-danger);
}

.chip-grey {
  @include chip($brand-disabled);
}
