// Menus https://www.google.com/design/spec/components/menus.html#menus-specs
// Dropdown buttons (mobile and desktop) https://www.google.com/design/spec/components/buttons.html#buttons-dropdown-buttons





.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: $brand-background;
  background: $brand-primary;
}
