.input-group {
  // may be in or outside of form-group

  .input-group-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px 0 10px;
    background-color: transparent;
    border-color: transparent;
    color: $brand-text;
  }
}

.mdc-text-field__input::-webkit-calendar-picker-indicator {
  display: block !important;
}
